// src/ProductList.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ProductList.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get-products.php`)
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/delete-product.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: selectedProductIds }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setProducts(prevProducts => prevProducts.filter(product => !selectedProductIds.includes(product.id)));
      } else {
        console.error('Error deleting products:', data.message);
      }
    })
    .catch(error => console.error('Error deleting products:', error));
  };

  const handleCheckboxChange = (event, id) => {
    console.log('Checkbox toggled:', id);
    if (event.target.checked) {
      setSelectedProductIds(prevIds => [...prevIds, id]);
    } else {
      setSelectedProductIds(prevIds => prevIds.filter(productId => productId !== id));
    }
  };

  return (
    <div>
      <div className='container'> 
        <div>
          <h1>Product List</h1>
        </div>
        <div className='btn-cont'>
          <div className='buttons'>
            <Link to="/add-product">
              <button>ADD</button>
            </Link>
          </div>
          <div className='buttons'>
            <button id='delete-product-btn' className='btn-del' onClick={handleDelete}>MASS DELETE</button>
          </div>
        </div>
      </div>

      <ul>
        {products.map((product) => (
          <li key={product.id}>
            <input
              id='delete-checkbox'
              type="checkbox"
              className="delete-checkbox"
              checked={selectedProductIds.includes(product.id)}
              onChange={(e) => handleCheckboxChange(e, product.id)}
            />
            <br/>
            <span>
              <h4>{product.sku} </h4>
              <h4>{product.name} </h4>
              <h4>{product.price} $</h4>
              {product.type === 'DVD' && `Size: ${product.size} MB`}
              {product.type === 'Book' && `Weight: ${product.weight} KG`}
              {product.type === 'Furniture' && `Dimensions: ${product.height} x ${product.width} x ${product.length}`}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
