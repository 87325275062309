import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

const AddProduct = () => {
  const [sku, setSku] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('DVD');
  const [size, setSize] = useState('');
  const [weight, setWeight] = useState('');
  const [dimensions, setDimensions] = useState({ height: '', width: '', length: '' });
  const [error, setError] = useState('');
  const [invalidFields, setInvalidFields] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newInvalidFields = {};

    // Check for required fields and validate types
    if (!sku) newInvalidFields.sku = true;
    if (!name) newInvalidFields.name = true;
    if (!price || isNaN(price)) newInvalidFields.price = true;
    if (type === 'DVD' && (!size || isNaN(size))) newInvalidFields.size = true;
    if (type === 'Book' && (!weight || isNaN(weight))) newInvalidFields.weight = true;
    if (type === 'Furniture') {
      if (!dimensions.height || isNaN(dimensions.height)) newInvalidFields.height = true;
      if (!dimensions.width || isNaN(dimensions.width)) newInvalidFields.width = true;
      if (!dimensions.length || isNaN(dimensions.length)) newInvalidFields.length = true;
    }

    setInvalidFields(newInvalidFields);

    if (Object.keys(newInvalidFields).length > 0) {
      setError('Please, provide the data of indicated type');
      return;
    }

    const productData = {
      sku,
      name,
      price,
      type,
      size: type === 'DVD' ? size : undefined,
      weight: type === 'Book' ? weight : undefined,
      height: type === 'Furniture' ? dimensions.height : undefined,
      width: type === 'Furniture' ? dimensions.width : undefined,
      length: type === 'Furniture' ? dimensions.length : undefined,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/add-product.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(productData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success) {
        navigate('/');
      } else if (data.message === 'SKU already exists') {
        setError('SKU must be unique. The SKU you entered already exists.');
      } else {
        setError(data.message || 'Failed to save the product. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching:', error);
      setError('Failed to save the product. Please try again.');
    }
  };

  return (
    <div>
      <h1>Add Product</h1>
      <form id="product_form" onSubmit={handleSubmit}>
        <label>
          SKU:
          <input
            id="sku"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            style={{ borderColor: invalidFields.sku ? 'red' : '' }}
          />
        </label>
        <br />
        <label>
          Name:
          <input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ borderColor: invalidFields.name ? 'red' : '' }}
          />
        </label>
        <br />
        <label>
          Price ($):
          <input
            id="price"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            style={{ borderColor: invalidFields.price ? 'red' : '' }}
          />
        </label>
        <br />
        <label>
          Type:
          <select
            id="productType"
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{ borderColor: invalidFields.type ? 'red' : '' }}
          >
            <option value="DVD">DVD</option>
            <option value="Book">Book</option>
            <option value="Furniture">Furniture</option>
          </select>
        </label>
        <br />

        {type === 'DVD' && (
          <>
            <p>Please, provide size (in MB).</p>
            <label>
              Size (MB):
              <input
                id="size"
                type="number"
                value={size}
                onChange={(e) => setSize(e.target.value)}
                style={{ borderColor: invalidFields.size ? 'red' : '' }}
              />
            </label>
          </>
        )}

        {type === 'Book' && (
          <>
            <p>Please, provide weight (in Kg).</p>
            <label>
              Weight (Kg):
              <input
                id="weight"
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                style={{ borderColor: invalidFields.weight ? 'red' : '' }}
              />
            </label>
          </>
        )}

        {type === 'Furniture' && (
          <>
            <p>Please, provide dimensions (Height x Width x Length) in cm.</p>
            <label>
              Height (cm):
              <input
                id="height"
                type="number"
                value={dimensions.height}
                onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
                style={{ borderColor: invalidFields.height ? 'red' : '' }}
              />
            </label>
            <br />
            <label>
              Width (cm):
              <input
                id="width"
                type="number"
                value={dimensions.width}
                onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
                style={{ borderColor: invalidFields.width ? 'red' : '' }}
              />
            </label>
            <br />
            <label>
              Length (cm):
              <input
                id="length"
                type="number"
                value={dimensions.length}
                onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
                style={{ borderColor: invalidFields.length ? 'red' : '' }}
              />
            </label>
          </>
        )}

        {error && <p className="error-message">{error}</p>}
        <button type="submit">Save</button>
        <button type="button" onClick={() => navigate('/')}>Cancel</button>
      </form>
    </div>
  );
};

export default AddProduct;
